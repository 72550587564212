import "./AboutMe.css"
import Button from "../Button/Button";

const AboutMe = () => {


    return (
        <div className="about-me" >

            <div className="about-me__main">

                <h3 className="main__heading">About</h3>

                <p className='about-me__text' >
                    Hi! My name is <span className="underline">Darshan</span> and I'm a Front-End Developer. 
                    I enjoy using my creativity and problem solving skills to 
                    create unique digital experiences.
                </p>

                <p  className='about-me__text' >
                    While I may be a junior, I’m overflowing with a passion for 
                    problem-solving and a drive to learn and grow. I’m not afraid 
                    to bring a fresh perspective to the table, and I’m committed 
                    to constantly improving my skills.
                </p>

                <p  className='about-me__text' >
                    I believe that with my potential and dedication 
                    I could make a great addition to your team.  If you're looking 
                    for a dynamic developer, hit me up and let's work on 
                    some projects together!
                </p>

                <div className="info__links about__icons">

                    <Button content={"LinkedIn"} link={"https://www.linkedin.com/in/darshandesai95/"} color={"blue"}/>
                    <Button content={"GitHub"} link={"https://github.com/darshandesai1095"} color={"purple"}/>
                    <Button content={"Résumé"} link={"Darshan_Desai_Resume.pdf"} color={"grey"}/>
                </div>

            </div>
        </div>
    )
}

export default AboutMe