import "./HomeAnimation.css"

const HomeAnimation = () => {

    return (
          <div className="home-animation__heading">

            <div className="flip-top">
              
              <div>
                <div className="heading__line space"> 
                  <h1>I'M DARSHAN</h1>
                  <div className="line__space"></div>
                </div>
              </div>

              <div>
                <div className="heading__line space"> 
                  <div className="line__space"></div>
                  <h1>A FRONTEND</h1>
                </div>
              </div>

              <div>
                <div className="heading__line space"> 
                  <h1>WELCOME TO MY</h1>
                </div>
              </div>

            </div>

            <div className="flip-bottom">

              <div>
                <div className="heading__line space"> 
                 <div className="line__space"></div>
                  <h1>DESAI</h1>
                </div>
              </div>

              <div>
                <div className="heading__line space"> 
                  <h1>DEVELOPER</h1>
                  <div className="line__space"></div>
                </div>
              </div>

              <div>
                <div className="heading__line space"> 
                  <div className="line__space"></div>
                  <h1>PORTFOLIO</h1>
                </div>
              </div>
              
            </div>

          </div>

    )
}

export default HomeAnimation