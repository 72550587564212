import './Navigation.css'
import navigate from '../functions/navigate'

const Navigation = () => {
    
    return (
        <div className='navigation'>
            <div className='navigation-items'>
                <p onClick={(e) => navigate(e, "project-1")} >Projects</p>
                <p>•</p>
                <p onClick={(e) => navigate(e, "about")} >About</p>
                <p>•</p>
                <p onClick={(e) => navigate(e, "contact")} >Contact</p>
            </div>
        </div>

    )
}

export default Navigation