import React, { useState } from 'react';
import './Contact.css';


const Contact = () => {

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [topic, setTopic] = useState("")
    const [message, setMessage] = useState("")


  return (
    <div className='get-in-touch'>
    <div className="contact">

        <div className='contact__details'>
            <h3 className="contact__title">
                Get In Touch
            </h3>

            <p>You can reach me anytime at darshandesai1995@gmail.com</p>
        </div>


        <form name="contactForm" method="POST" data-netlify="true">

            <input 
                type='hidden' 
                name='form-name'
                value='contactForm' 
            />


            <input type="hidden" name="subject" 
                   value="Contact form submission from darshandesai.io" />


            <div className='form__inputs'>

                    <label className='name'>
                        <input
                            name="name"
                            className='form__input'
                            placeholder='Name'
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </label>

                    <label className='email'>
                        <input
                            name="email"
                            className='form__input'
                            placeholder='Email'
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </label>

                    <label className='topic'>
                        <input
                            name="topic"
                            className='form__input'
                            placeholder='Subject (optional)'
                            type="text"
                            value={topic}
                            onChange={(e) => setTopic(e.target.value)}
                        />
                    </label>

                    <label className='message'>
                        <textarea
                            name="message"
                            className='form__input'
                            placeholder='Enter message here...'
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}/>
                    </label>
            </div>

            <button type="submit">Submit Your Message</button>
        </form>

    </div>
    </div>
  );
}


export default Contact