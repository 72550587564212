import React from "react";
import "./Home.css"
import Navigation from "../Navigation/Navigation";
import HomeAnimation from "../HomeAnimation/HomeAnimation";
import ScrollToProjects from "../ScrollToProjects/ScrollToProjects";

const Home = () => {

    return (
        <div className="home">

          <HomeAnimation/>
          <Navigation/>
          <ScrollToProjects/>

        </div>

    )
}

export default Home