import './App.css';
import Home from './components/Home/Home';
import AboutMe from './components/AboutMe/AboutMe';
import ProjectPage from './components/ProjectPage/ProjectPage';
import projects from './data/ProjectInfo';
import Contact from './components/Contact/Contact';
// import NavigationBar from './components/NavigationBar/NavigationBar';


function App() {

  return (
    <div className="App">

      {/* <NavigationBar/> */}

      <section id="home"        className="section"> <Home/> </section>
      <section id="project-1"   className="section"> <ProjectPage project={projects[0]} projectNo={"1"}/> </section>
      <section id="project-2"   className="section"> <ProjectPage project={projects[1]} rowReverse={true} projectNo={"2"}/> </section>
      <section id="project-3"   className="section"> <ProjectPage project={projects[2]} projectNo={"3"}/> </section>
      <section id="about"       className="section"> <AboutMe/> </section>
      <section id="contact"     className="section"> <Contact/> </section>

    </div>
  );
}

export default App;