import navigate from "../functions/navigate"
import "./ScrollToProjects.css"

const ScrollToProjects = () => {

    return (
        <div className="scroll-to-projects" onClick={(e) => navigate(e, "project-1")}>
            <p>Check Out My Recent Work!</p>
            <p>➜</p>
        </div>

    )
}

export default ScrollToProjects