import './ProjectPage.css';
import Button from '../Button/Button';


function ProjectPage({ project, rowReverse}) {

    const { title, desc, technologies, site, github, codepen, image} = project


    return (

        <div 
            className='project-page__full'
            style={{
                backgroundColor: `${rowReverse ? "var(--background-color" : "var(--background-color-secondary)"}`
            }}
        >

            <div className={`project-page ${rowReverse ? "reverse" : null}`}>

                <div className='project__info' style={{color: null}}>
                    <h3>{title}</h3>
                    <p className='info__description'>{desc}</p>
                    <p className='info__technologies' style={{color: null}}>{technologies}</p>
                    <div className='info__links'>

                        <Button content={"Demo"} link={site} color={"blue"}/>
                        <Button  content={"Github"} link={github} color={"purple"}/>
                        { codepen && <Button content={"Sandbox"} link={codepen} color={"orange"}/> }

                    </div>
                </div>
                <div className='project__image'>
                    <img src={image} alt="Algorithm Visualiser UI" onClick={() => window.open(site, "blank")} title="Go To Site"/>

                </div>
            </div>

        </div>
    );
}

export default ProjectPage;
