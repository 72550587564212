import "./Button.css"

const Button = ({ content, link, color }) => {

    return (
          <button className={`button ${color}`} onClick={() => window.open(link, "blank")}>
              {content}
          </button>

    )
}

export default Button