const projects = [
    {
        title: `Algorithm Visualizer`,
        desc: `A site which allows users to visualize a range of sorting 
        algorithms including; quick sort, heap, sort, merge sort, selection 
        sort, bubble sort, etc.`,
        technologies: "React JS, Redux-Toolkit",
        site: "https://classy-cheesecake-233ae7.netlify.app/",
        github: "https://github.com/darshandesai1095/algorithm-visualizer",
        codepen: "https://codesandbox.io/s/algorithm-visualizer-xsrxfq",
        image: "algo-vis-2.png",
        primaryColor: "#FF194D",
        secondaryColor: "#ff8ca6" ,
    },

    {
        title: "Graph Creator",
        desc: `A graph creator site inspired by ChartBlocks. 
        The application allows users to easily create, customize, 
        and download bar charts, line charts, and pie charts.` ,
        technologies: "React JS, Redux-Toolkit, Chart JS, Material UI",
        site: "https://gentle-chimera-e87178.netlify.app/",
        github: "https://github.com/darshandesai1095/graph-creator",
        codepen: false,
        image: "graph-creator-2.png",
        primaryColor: "#7371fc",
        secondaryColor: "#cdc1ff",
    },

    {
        title: "Realtime Chat App",
        desc: `The Real-time Chat Application - a modern web-based 
        communication platform that allows users to create groups and engage in real-time 
        conversations.` ,
        technologies: `React JS, Redux-Toolkit (rtk query, redux-thunk), 
        Firebase (authentication), Node JS, Express JS, Mongo DB, Socket.IO, Redis`,
        site: "https://boisterous-sunburst-f3d32f.netlify.app/",
        github: "https://github.com/darshandesai1095/reat-time-chat-app",
        codepen: false,
        image: "chat-app-3.png",
        primaryColor: "#2b2d42",
        secondaryColor: "#8d99ae",
    },
]

export default projects